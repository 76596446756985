.page {
  background: #f0f4fa;
  position: relative;
}

.logo_container {
  left: 0;
  position: absolute;
  right: 0;
  top: 50px;
}

.logo {
  padding: 0 24px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.container {
  padding: 114px 16px 40px;
}

.title {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.text {
  color: #566376;
  font-size: 16px;
  line-height: 28px;
  margin: 12px auto 40px;
  max-width: 295px;
  text-align: center;
}

.help {
  font-size: 14px;
  line-height: 20px;
  margin: 40px auto 0;
  max-width: 295px;
  text-align: center;
}

.link {
  color: #1456b8;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
}

@media (min-width: 682px) {
  .text {
    margin-bottom: 0;
  }
}
