.skeleton_container {
  height: 600px;
  max-width: 1000px;
  margin: 40px auto 0;
}

.calendly_container {
  height: 600px;
  max-width: 1000px;
  margin: 0 auto;
}

@media (min-width: 682px) {
  .calendly_container {
    height: 698px;
  }
}
